import React from "react";
import "./servicecard.css"
import { payload, getAvailableIndex, updateIndex } from "../Connection";

export default function ServiceCard(props){

    const [currentVal,updateVal] = React.useState(0);
    const [thisIndex,setThisIndex] = React.useState(undefined);
    
    const addOrUpdateValue=async()=>{
        console.log("first",thisIndex)
        if (thisIndex){
            payload['service'][thisIndex]['quant']=currentVal+1        }
        else{
            var available = await getAvailableIndex()
            console.log("avail",available)
            await setThisIndex(available);
            console.log("after",thisIndex);
            await updateIndex(available+1)
            console.log(getAvailableIndex())
            payload['service'].push({name:props.sname,quant:currentVal+1})

        }
        console.log(payload)
    }
    
    return(
        <div className="service-info-card">
            <div className="service-img">
                <img src={props.icon} height={props.height?props.height:"100%"}  width={props.width?props.width:"100%"} style={{float:props.float?props.float:"left", marginTop:props.top?props.top:"0"}}/>
            </div>
            <div className="service-info" style={{flexDirection:props.direction}}>
                <p style={{alignSelf:"flex-start", maxWidth:"100px",fontWeight:"400"}}>{props.sname}</p>
                <div style={{display:"flex", justifyContent:"center", gap:"20px", border:"2px solid rgb(255,77,77)", width:"110px", borderRadius:"20px"}}>
                    <p style ={{cursor:"pointer"}} onClick={
                        ()=>{
                            if(!props.val){
                            currentVal>0?updateVal(currentVal-1):updateVal(0)}
                            else{
                                currentVal>0?updateVal(currentVal-500):updateVal(0)
                            }
                        }
                    }>-</p>
                    <p>{currentVal}</p>
                    <p style ={{cursor:"pointer"}} onClick={
                        ()=>{
                            if(!props.val){
                            updateVal(currentVal+1)
                                payload['data'][props.sname]=currentVal+1
                        }
                            else{
                                updateVal(currentVal+500)
                            }     
                            addOrUpdateValue();                       
                        }
                    }>+</p>
                </div>
            </div>
        </div>
    )
}