import React from "react";
import "./Form.css";
import { updateOTP } from "./SlideUp";

export default function Form(props){

    var val= React.createRef();

    React.useEffect(()=>{
        console.log(val.current.value)
        if(props.type==='text'){
            if(window.localStorage.getItem('name')){
                val.current.value=window.localStorage.getItem('name')
                props.func(val.current.value)
            }
        }
        else if(props.type==='number'){
            if(window.localStorage.getItem("number")){
                val.current.value=window.localStorage.getItem("number")
                updateOTP(true)
                props.func(val.current.value)
            }
        }
    },[])

    return(
        <div className="user-form">
            <input placeholder={props.def} type={props.type} max={props.maxLen} ref={val} onInput={()=>{
                props.maxLen==10?val.current.value=val.current.value.slice(0,props.maxLen):<></>
            }} onChange={()=>{
                props.func(val.current.value)

                if(val.current.value.length===10 && props.type==="number"){
                    updateOTP(true)
                }else if(val.current.value.length!==10 && props.type==="number"){
                    updateOTP(false)
                }

            }}></input>
        </div>
    )
}