import React from "react";
import "./servicecard.css"
import { updateLeadCount } from "../Connection";
import defaultUser from "../images/user.jpg";

export default function Provider(props){
    return(
        <div className="service-info-card" style={{  border: "0px solid rgb(142, 142, 142);",
            borderRadius: "10px",
            backgroundColor:"#ebebeb",
            padding:"5px 10px"
            }}>
            <div className="service-img">
                <img src={props.image?props.image:defaultUser} height={"100%"} width={"100%"} style={{borderRadius:"60px"}}/>
            </div>
            <div className="service-info">
                <div className="sub-info-service">
                    <p style={{alignSelf:"flex-start", fontWeight:900,fontSize:"0.8rem"}}>{props.name}</p>
                    <div className="rating" style={{background:"#73b373",width:"80px"}} onClick={()=>{
                        updateLeadCount(props.num,props.cost)
                        console.log(props.num)
                        window.open(`tel:${props.num}`)
                    }}>
                        <p style={{fontWeight:500}}>&#128222; Call</p>
                    </div>
                </div>

                <div className="sub-info-service">
                <div style={{display:"flex", justifyContent:"space-evenly"}}>
                    <div className="rating">
                        <p style={{fontSize:"0.8rem"}}> ★ {props.rating}</p>
                    </div>
                </div>
                <p style={{fontWeight:"500", fontFamily:"cardtite" , fontSize:"1rem", marginRight:"10px"}}>Approx. &#8377; {props.cost}</p>
                </div>
                <p style={{color:"red",fontSize:"0.7rem", fontWeight:500}}>Call Me For Exact Service Cost*</p>
            </div>
        </div>
    )
}