import React from 'react';
import './Card.css'; // Import your Card CSS file for styling

const Card = ({ title,Icon }) => {
  return (
    <div className="card" onClick={()=>{document.getElementById("slide").style.transform="translateY(0%)"}}>
      <div className="box">
        <img src={Icon} height={"100%"}/>
      </div>
      <h3 className="title-card">{title}</h3>
    </div>
  );
};

export default Card;
