import './App.css';
import React from 'react';
import Card from './components/Card';
import SlideUp from './components/SlideUp';
import elec from "./images/electricity.gif"
import Plumbing from "./images/plumbing.gif";
import Battery from "./components/icons/GIF Icons/2. Battery Services/battery.gif";
import AC from "./components/icons/GIF Icons/6. Air Conditioner/air-conditioner.gif";
import Apl from "./components/icons/GIF Icons/7. Appliances/home-appliance.gif";
import Ro from "./components/icons/GIF Icons/8. RO/water.gif";
import Carp from  "./components/icons/GIF Icons/12. Carpenter/Carpenter - Repair/hammer.gif";
import Tanks from "./components/icons/GIF Icons/16. Water Tank Cleaning/water-tower (1).gif";
import Weld from "./components/icons/GIF Icons/24. Welding/welder.gif";
import Painter from "./components/icons/GIF Icons/14. Painter/paint-roller.gif";
import Roof from "./components/icons/GIF Icons/Roof Design Artist - Plaster of paris/mandala.gif";
import Pest from "./components/icons/GIF Icons/Pest Control/insecticide.gif";
import furniture from "./components/icons/GIF Icons/12. Carpenter/Carpenter.gif";
import AddressSelector from './components/addressSelector';
import { electricityCards,batteryServices, tapServices, acServices, appliance, carpenterRepair, Tank , Welding,RO,Painting, PestControl,RoofDesign,carpenterMaking} from './components/cardData';
import { updatePayload } from './Connection';
import { serviceObjects } from './components/cardData';
import {setAPP} from './Update';

export var openFullProfile;
export var updateAddSearch;
export var updatescreen;
function App() {
/******************************************************************/
  const CURRENT_VERSION=0.006; //ALWAYS CHANGE IN EVERY DAMN UPDATE
/******************************************************************/

  const arr =['Technician','Electrician','Plumber','Carpenter','Sab kuch'];
  const [target,updateTarget] = React.useState(0);
  const [currentCards,setCards] = React.useState([]);
  const [contains,updateContains] = React.useState([true,true,true,true,true,true,true,true,true,true,true,true,true])
  const [searchDisp,setSearchDisp] = React.useState("none")
  const [f,chanegupdating] =React.useState("flex")
  updatescreen =chanegupdating
  updateAddSearch = setSearchDisp;


  React.useEffect(()=>{
   const interval= setInterval(async ()=>{
      var tar=target+1;
      tar++;
      tar%=arr.length;
      updateTarget(tar);
    },1000);

    return ()=>{clearInterval(interval)}
    
  },[target])

  React.useEffect(()=>{
    setAPP(CURRENT_VERSION);
    
  },[])
  
  // there is probably a better way to do this filtered search
const updateVisibleList=async (e)=>{
  if (e.target.value.length<1){
    await  updateContains([true,true,true,true,true,true,true,true,true,true,true,true,true])
  }
  else{
    var updationArray=[true,true,true,true,true,true,true,true,true,true,true,true,true];
    var index=0;
    
    for(const property in serviceObjects){ 
      if (property.toLowerCase().includes(e.target.value.toLowerCase())){
        updationArray[index]=true;
      }
      else{
          for (const i in serviceObjects[property]){
              
            if (serviceObjects[property][i].toLowerCase().includes(e.target.value.toLowerCase())){
                
              updationArray[index]=true;
                break;

              }else{
                
                updationArray[index]=false;

              }
            }
        }
        index++;
    }
   await updateContains(updationArray);
  }
}

  return (
    <div className="App">
      <div style={{height:"100%", width:"100%", position:"fixed", display:f, backgroundColor:"white", zIndex:99999999999}}>
        <h1>U P D A T I N G</h1>
      </div>
      <AddressSelector disp={searchDisp}/>

      <div className='header'>
        <div className='header-content'>
          <h1 className='title' onClick={async ()=>{
            // chanegupdating("flex")
            // await update(CURRENT_VERSION);
            // chanegupdating("none")
              
  }}>Reachmayy v0.0.6</h1>
          <div style={{width:"100%", marginTop:"10px"}}>
            <input type='text' onChange={(e)=>{updateVisibleList(e)}} placeholder={"Search for "+arr[target]+" Reachmayy.."}></input>
          </div>
        </div>
      </div>
      <div className='main-content' onClick={async()=>{
       
      }}>
       {contains[0] &&   <div onClick={()=>{ setCards(electricityCards); updatePayload("fos","electrician")}}><Card title={"Electricity"} Icon={elec} /></div>}
       {contains[1] && <div onClick={()=>{ setCards(batteryServices); updatePayload("fos","battery")}}><Card title={"Battery"} Icon={Battery}/></div>}
       {contains[2] && <div onClick={()=>{ setCards(tapServices); updatePayload("fos","plumbing")}}><Card title={"Plumbing"} Icon={Plumbing} /></div>}
        {contains[3] &&<div onClick={()=>{ setCards(acServices); updatePayload("fos","ac")}}><Card title={"AC Services"} Icon={AC} /></div>}
        {contains[4] && <div onClick={()=>{ setCards(appliance); updatePayload("fos","appliance")}}><Card title={"Appliances"} Icon={Apl} /></div>}
        { contains[5] &&<div onClick={()=>{ setCards(RO); updatePayload("fos","RO")}}><Card title={"RO"} Icon={Ro} /></div>}
        { contains[6] &&<div onClick={()=>{ setCards(carpenterRepair); updatePayload("fos","furniture repair")}}><Card title={"Furniture Repair"}Icon={Carp} /></div>}
        {contains[7] &&<div onClick={()=>{ setCards(carpenterMaking); updatePayload("fos","furniture making")}}><Card title={"Furniture Making"}Icon={furniture} /></div>}
        { contains[8] &&<div onClick={()=>{ setCards(Tank); updatePayload("fos","tank")}}><Card title={"Water Tank"}Icon={Tanks} /></div>}
        {contains[9] &&<div onClick={()=>{ setCards(Welding); updatePayload("fos","welding")}}><Card title={"Welding"}Icon={Weld} /></div>}
        {contains[10] &&<div onClick={()=>{ setCards(Painting); updatePayload("fos","painting")}}><Card title={"Painter"}Icon={Painter} /></div>}
        {contains[11] &&<div onClick={()=>{ setCards(PestControl); updatePayload("fos","pest control")}}><Card title={"Pest Control"}Icon={Pest} /></div>}
        {contains[12] &&<div onClick={()=>{ setCards(RoofDesign); updatePayload("fos","roof")}}><Card title={"Roof Design"}Icon={Roof} /></div>}
      </div>
      <SlideUp cards={currentCards} updateCard={setCards} />
      
    </div>
  );
}

export default App;
