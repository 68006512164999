import React from "react";
import { updateAddSearch } from "../App";
import { setCurrentAddress, setLatLong } from "./SlideUp";

export default function AddVal(props){
    return (
        <div style={{width:"100%", backgroundColor:"white", cursor:"pointer", borderTop:"1px solid grey", textAlign:"left",padding:"5px 0px",maxWidth:"600px"}}
            onClick={()=>{
                setLatLong[0](props.lat)
                setLatLong[1](props.long)
                setCurrentAddress(props.name)
                updateAddSearch("none")
        }}>
            <p style={{margin:"0px 20px", fontSize:"1.2rem"}}> {props.name}</p>
            <p style={{color:"grey",margin:"0px 20px", fontSize:"0.7rem"}}>{props.lat} {props.long}</p>
        </div>
    )
}