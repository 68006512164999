import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { createClient } from '@supabase/supabase-js'
import { updatescreen } from './App';
import { Device } from '@capacitor/device';

const setAPP = async(version)=>{

    const info = await Device.getInfo()
    if(info.platform!=="web"){
        let isSecondInstall = await window.localStorage.getItem("installed")
        console.log(isSecondInstall)
        if(!isSecondInstall){
            await Filesystem.writeFile({ //folder was not being created with the download file so we are
                path:"/build/l", // making a new empty file in every update to ensure that the folder exists
                data:"",
                directory:Directory.Data,
                recursive:true
            })
            await Filesystem.writeFile({
                path:"/build/static/css/l",
                data:"",
                directory:Directory.Data,
                recursive:true
            })
            await Filesystem.writeFile({
                path:"/build/static/js/l",
                data:"",
                directory:Directory.Data,
                recursive:true
            })
            await Filesystem.writeFile({
                path:"/build/static/media/l",
                data:"",
                directory:Directory.Data,
                recursive:true
            })
            
        }
        await window.localStorage.setItem("installed",true)
        //await downloadImages(version)
        await update(version)
    }
    else{
        updatescreen("none")
    }
}

const downloadImages= async(version)=>{
    let previousInstalledVersion = Number(await window.localStorage.getItem("VERSION"))


    if(previousInstalledVersion!==version){ //look to Download the image files if and only if the app version has just been updated or On first Install
        let url = await getFilePublicURL()
        for(let i=0; i < url.length; i++){
            let v = window.localStorage.getItem(url[i][1])
            console.log(v)
            if(!v){
                await Filesystem.downloadFile({
                    url:url[i][0],
                    path:"/build/static/media/"+url[i][1],
                    directory:Directory.Data
                })
                
            }
            await window.localStorage.setItem(url[i][1],true)
        }

    }
}

const update = async(constV)=>{
    
    var version = await fetch("https://reachmayy.vercel.app/match_version_customer")
    version = await version.text()
    version = await JSON.parse(version)
    
    let previousInstalledVersion = Number(await window.localStorage.getItem("VERSION"))
        
    await console.log(previousInstalledVersion,version.version,"index")
     if(previousInstalledVersion!==version.version){
          
                let url = await getFilePublicURL("HTML")
                await Filesystem.downloadFile({
                    url:url,
                    path:"/build/index.html",
                    directory:Directory.Data,
                })

                url = await getFilePublicURL("css")
                await Filesystem.downloadFile({
                    url:url[0],
                    path:"/build/static/css/"+url[1],
                    directory:Directory.Data,
                    method:"GET"
                })

                url = await getFilePublicURL("js")
                    await Filesystem.downloadFile({
                    url:url[0],
                    path:"/build/static/js/"+url[1],
                    directory:Directory.Data,
                    method:"GET"
                })
            }
                await downloadImages(version.version)
                await window.localStorage.setItem("VERSION",version.version)
                const y = await Filesystem.getUri({path:"/build",directory:Directory.Data})
                var x=await y.uri.slice(8);
                
                if(version.version!=constV){
                    console.log(constV,version.version,window.localStorage.getItem("VERSION"))
                    await Capacitor.Plugins.WebView.setServerBasePath({path:x}) 
                }

                if(version.version==constV){
                    updatescreen("none")
                }


          
    
    // await console.log(window.localStorage.getItem("isUpdateBlocked")==false ,isInstalled,window.localStorage.getItem("isUpdateBlocked") )

    // if(!window.localStorage.getItem("isUpdateBlocked")){   
    //     console.log(
    //     "updated server"
    //     )
        
    //     await window.localStorage.setItem('isUpdateBlocked',true)

    //     // Capacitor.Plugins.WebView.persistServerBasePath({path:x})
          
        
    //  }else if(window.localStorage.getItem("isUpdateBlocked")){
    //     window.localStorage.setItem('isUpdateBlocked',false)
    //  }
     
    
}


const getFilePublicURL=async(fileType)=>{
    
    const client = createClient("https://idhwdvaotxfkpzkxpglw.supabase.co","eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlkaHdkdmFvdHhma3B6a3hwZ2x3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTAyOTEwNTAsImV4cCI6MjAwNTg2NzA1MH0.GRSvvcQX4M27ZDw_IGAwfyrZfx4WtsvIytJPO6S9Fd8")

    if (fileType==="HTML"){
        const data =await client.storage.from("build").getPublicUrl("index.html")
        return data.data.publicUrl
    }
    if (fileType==="css"){
        const data =await client.storage.from("build").list("static/css")
        const url = await client.storage.from("build").getPublicUrl("static/css/"+data.data[0].name)
        return [url.data.publicUrl,data.data[0].name]
    }
    if (fileType==='js'){
        const data =await client.storage.from("build").list("static/js")
        const url = await client.storage.from("build").getPublicUrl("static/js/"+data.data[0].name)
        return [url.data.publicUrl,data.data[0].name]
    }

    const data =await client.storage.from("build").list("static/media")
    var pubURL = []
    data.data.forEach(async(file)=>{
        const url = await client.storage.from("build").getPublicUrl("static/media/"+file.name)
        pubURL.push([url.data.publicUrl,file.name])
    })
    return pubURL;

}

export {update, setAPP};