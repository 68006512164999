import ServiceCard from "./ServicesCard";
import Bulb from "./icons/Bulb.gif";
import Fan from "./icons/fan.gif";
import Tube from "./icons/GIF Icons/1. Electrician/Tubelight/lightbulb (1).gif";
import MCB from "./icons/GIF Icons/1. Electrician/MCB/MCB.gif";
import Chand from "./icons/chandelier.gif";
import Drill from "./icons/GIF Icons/1. Electrician/Drill/drill.gif";
import Bell from "./icons/GIF Icons/1. Electrician/Door Bell/switch.gif";
import Battery from  "./icons/GIF Icons/2. Battery Services/battery.gif";
import Tap from "./icons/GIF Icons/4. Plumber/Tap/tap.gif";
import IndC from "./icons/GIF Icons/4. Plumber/Commode/Indian Commode/Indian Commode.gif";
import Jet from "./icons/GIF Icons/4. Plumber/Jet Spray/water-hose.gif"
import WestC from "./icons/GIF Icons/4. Plumber/Commode/Western (Floor Mounted)/Western (Floor Mounted).gif";
import AC from "./icons/GIF Icons/6. Air Conditioner/air-conditioner.gif";
import Service from "./icons/GIF Icons/6. Air Conditioner/Wet Servicing/Wet Servicing.gif";
import Gas from "./icons/GIF Icons/6. Air Conditioner/Gas/Gas Refill.gif";
import Washing from "./icons/GIF Icons/7. Appliances/Washing Machine/smart-washing-machine.gif";
import Ref from "./icons/GIF Icons/7. Appliances/Refrigerator/refrigerator.gif";
import Micro from "./icons/GIF Icons/7. Appliances/Microwave/microwave.gif";
import Heater from "./icons/GIF Icons/7. Appliances/Heater/Heater.gif";
import Iron from "./icons/GIF Icons/7. Appliances/Iron/iron-.gif";
import Tele from  "./icons/GIF Icons/7. Appliances/Television/TV.gif";
import Mix from "./icons/GIF Icons/7. Appliances/Mixer Grinder/blender.gif";
import Row from "./icons/GIF Icons/8. RO/water.gif";
import Ron from "./icons/GIF Icons/8. RO/no-water.gif";
import Hammer from "./icons/GIF Icons/12. Carpenter/Carpenter - Repair/hammer.gif";
import Tanks from "./icons/GIF Icons/16. Water Tank Cleaning/water-tower (1).gif";
import Weld from "./icons/GIF Icons/24. Welding/welder.gif";
import Painter from "./icons/GIF Icons/14. Painter/paint-roller.gif";
import flush from "./icons/GIF Icons/4. Plumber/Plumber - Flush Tank.png";
import Lid from "./icons/GIF Icons/4. Plumber/Toilet seat/Seat.gif";
import waterMixer from "./icons/GIF Icons/4. Plumber/Plumber - Hot and Cold Mixer.png";
import Leakage from "./icons/GIF Icons/4. Plumber/Plumber - Leakage/water-drops.gif";
import WindowAC from "./icons/GIF Icons/6. Air Conditioner/AC - Window AC.png";
import Hinges from "./icons/GIF Icons/12. Carpenter/Carpenter - Hinges Repair.png";
import Handles from "./icons/GIF Icons/12. Carpenter/Carpenter - Door Handles.png";
import Stopper from "./icons/GIF Icons/12. Carpenter/Carpenter - Door Stopper.png";
import Drawer from "./icons/GIF Icons/12. Carpenter/Carpenter - Drawer.png";
import BedB from "./icons/GIF Icons/Pest Control/1. Bed Bug.png";
import Lizard from "./icons/GIF Icons/Pest Control/2. Lizard.png";
import Mosquito from "./icons/GIF Icons/Pest Control/3. Mosquito.png";
import cockRoach from "./icons/GIF Icons/Pest Control/4. Cockroach.png";
import Rodent from "./icons/GIF Icons/Pest Control/5. Rodents.png";
import Termite from  "./icons/GIF Icons/Pest Control/6. Termite.png";
import Roof from "./icons/GIF Icons/Roof Design Artist - Plaster of paris/mandala.gif";
import Bed from "./icons/GIF Icons/12. Carpenter/Bed/bed.gif";
import Sofa from "./icons/GIF Icons/12. Carpenter/Sofa/sofa.gif";
import Wardrobe from "./icons/GIF Icons/12. Carpenter/Wardrobe/closet.gif";

export const electricityCards =[
  <ServiceCard sname="Bulb Socket Installation" direction={"row"} icon={Bulb}/>,
  <ServiceCard sname="Bulb Socket Repair" direction={"row"} icon={Bulb}/>,
  <ServiceCard sname="Tubelight Installation" direction={"row"} icon={Tube}/>,
  <ServiceCard sname="Tubelight Holder Repair" direction={"row"} icon={Tube}/>,
  <ServiceCard sname="Switch/Socket Installation" direction={"row"} icon={Bell}/>,
  <ServiceCard sname="Switch/Socket Repair" direction={"row"} icon={Bell}/>,
  <ServiceCard sname="Fan Installation" direction={"row"} icon={Fan}/>,
  <ServiceCard sname="Fan Repair" direction={"row"} icon={Fan}/>,
  <ServiceCard sname="MCB Installation" direction={"row"} icon={MCB}/>,
  <ServiceCard sname="MCB Repair"direction={"row"} icon={MCB}/>,
  <ServiceCard sname="Chandelier" direction={"row"} icon={Chand}/>,
  <ServiceCard sname="Drilling" direction={"row"} icon={Drill}/>,
  <ServiceCard sname="Doorbell Installation" direction={"row"} icon={Bell}/>,
  <ServiceCard sname="Doorbell Repair" direction={"row"} icon={Bell}/>
]

export const batteryServices = [
  <ServiceCard sname="Battery Installation" direction={"row"} icon={Battery}/>,
  <ServiceCard sname="Battery Repair" direction={"row"} icon={Battery}/>,
]

export const tapServices = [

  <ServiceCard sname="Tap Installation" direction={"row"} icon={Tap}/>,
  <ServiceCard sname="Tap Repair"  direction={"row"} icon={Tap}/>,
  <ServiceCard sname="Water Mixer (Hot & Cold) Installation"  direction={"row"}icon={waterMixer} height={"80%"} width={"80%"}/>,
  <ServiceCard sname="Water Mixer (Hot & Cold) Repair"  direction={"row"}icon={waterMixer} height={"80%"} width={"80%"}/>,
  <ServiceCard sname="Commode Lid Repair"  direction={"row"}icon={Lid}/>,
  <ServiceCard sname="Flush Tank"  direction={"row"}icon={flush} height={"80%"} width={"80%"}/>,
  <ServiceCard sname="Commode Installation (Indian)"  direction={"row"} icon={IndC} />,
  <ServiceCard sname="Commode Installation (Western)"  direction={"row"} icon={WestC}/>,
  <ServiceCard sname="Jetspray Installation"  direction={"row"} icon={Jet}/>,
  <ServiceCard sname="Jetspray Repair"  direction={"row"} icon={Jet}/>,
  <ServiceCard sname="Leakage"  direction={"row"}icon={Leakage}/>,
]

export const acServices = [

  <ServiceCard sname="Window AC Installation"  direction={"row"} icon={WindowAC} height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Window AC Service"  direction={"row"} icon={Service}/>,
  <ServiceCard sname="Gas Refilling Window AC"  direction={"row"} icon={Gas}/>,
  <ServiceCard sname="Split AC Installation"  direction={"row"} icon={AC}/>,
  <ServiceCard sname="Split AC Service"  direction={"row"} icon={Service}/>,
  <ServiceCard sname="Gas Refilling Split AC"  direction={"row"} icon={Gas}/>,
]

export const appliance = [

  <ServiceCard sname="Washing Machine (Visit Charges)"  direction={"row"} icon={Washing}/>,
  <ServiceCard sname="Refrigerator (Visit Charges)"  direction={"row"} icon={Ref}/>,
  <ServiceCard sname="Microwave (Visit Charges)"  direction={"row"} icon={Micro}/>,
  <ServiceCard sname="Mixer Grinder (Visit Charges)"  direction={"row"} icon={Mix}/>,
  <ServiceCard sname="Heater (Visit Charges)"  direction={"row"} icon={Heater}/>,
  <ServiceCard sname="Iron (Visit Charges)"  direction={"row"} icon={Iron}/>,
  <ServiceCard sname="Television (Visit Charges)"  direction={"row"} icon={Tele}/>,

]
export const RO =[
  <ServiceCard sname="RO Installation"  direction={"row"} icon={Row}/>,
  <ServiceCard sname="RO Service"  direction={"row"} icon={Ron}/>,
]

export const carpenterRepair = [

  <ServiceCard sname="Hinges"  direction={"row"} icon={Hinges}  height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Door Handles"  direction={"row"} icon={Handles} height={"80%"} width={"80%"} top={"10px"} float={"right"}/>,
  <ServiceCard sname="Drawer"  direction={"row"} icon={Drawer}/>,
  <ServiceCard sname="Stopper"  direction={"row"} icon={Stopper} height={"80%"} width={"80%"} top={"10px"} />,
  <ServiceCard sname="Other Work"  direction={"row"} icon={Hammer}/>,
]

export const carpenterMaking = [

  <ServiceCard sname="Sofa"  direction={"row"} icon={Sofa} isSingle={true}/>,
  <ServiceCard sname="Wardrobe"  direction={"row"} icon={Wardrobe} isSingle={true}/>,
  <ServiceCard sname="Bed"  direction={"row"} icon={Bed} isSingle={true}/>
]

export const Tank = [
  <ServiceCard sname="Tank Cleaning (In Liters)"  direction={"row"} icon={Tanks} val={1}/>,
]

export const Welding =[
  <ServiceCard sname="Welding (Per Sq. ft)"  direction={"row"} icon={Weld}/>
]

export const Painting = [
  <ServiceCard sname="Painting (per sq.ft)"  direction={"row"} icon={Painter}/>

]

export const PestControl = [
  <ServiceCard sname="Bed Bugs (Per Room)"  direction={"row"} icon={BedB}  height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Cockroach (Per Room)"  direction={"row"} icon={cockRoach}  height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Mosquito (Per Room)"  direction={"row"} icon={Mosquito}  height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Rodent (Per Room)"  direction={"row"} icon={Rodent}  height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Termite (Per Room)"  direction={"row"} icon={Termite}  height={"80%"} width={"80%"} top={"10px"}/>,
  <ServiceCard sname="Lizard (Per Room)"  direction={"row"} icon={Lizard}  height={"80%"} width={"80%"} top={"10px"}/>
]

export const RoofDesign = [
  <ServiceCard sname="Plaster of Paris (Per Sq. ft)"  direction={"row"} icon={Roof}/>
]

export const serviceObjects={
  "electricity":["Bulb Socket Installation",
  "Bulb Socket Repair",
  "Tubelight Installation",
  "Tubelight Holder Repair",
  "Switch/Socket Installation" ,
  "Switch/Socket Repair",
  "Fan Installation",
  "Fan Repair",
  "MCB Installation",
  "MCB Repair",
  "Chandelier" ,
  "Drilling",
  "Doorbell Installation",
  "Doorbell Repair" ],

  "battery":["Battery Installation",
  "Battery Repair"],

  "plumbing":["Tap Installation",
  "Tap Repair",
  "Water Mixer (Hot & Cold) Installation",
  "Water Mixer (Hot & Cold) Repair",
  "Commode Lid Repair",
  "Flush Tank",
  "Commode Installation (Indian)",
  "Commode Installation (Western)", 
  "Jetspray Installation",
  "Jetspray Repair",
  "Leakage"],

  "ac":["Window AC Installation",
  "Window AC Service",
  "Gas Refilling Window AC",
  "Split AC Installation",
  "Split AC Service",
  "Gas Refilling Split AC" ],

  "appliances":["Washing Machine (Visit Charges)",
  "Refrigerator (Visit Charges)",
  "Microwave (Visit Charges)",
  "Mixer Grinder (Visit Charges)",
  "Heater (Visit Charges)",
  "Iron (Visit Charges)",
  "Television (Visit Charges)"],
  
  "RO":["RO Installation",
  "RO Service"],
  
  "furniture repair":["Hinges",
  "Door Handles",
  "Drawer",
  "Stopper",
  "Other Work"],

  "furniture making":["Sofa","Wardrobe","Bed"],

  "water tank":["Tank Cleaning (In Liters)"],

  "welding":["Welding (Per Sq. ft)"],

  "painter":["Painting (per sq.ft)"],

  "pest control":["Bed Bugs (Per Room)",
  "Cockroach (Per Room)",
  "Mosquito (Per Room)",
  "Rodent (Per Room)",
  "Termite (Per Room)",
  "Lizard (Per Room)"],

  "roof design":["Plaster of Paris (Per Sq. ft)"],
}