import React from "react";
import "./slider.css";
import Form from "./From";
import { getData, getOTP, payload, resetPayload } from "../Connection";
import OTPinput from "./OTP";
import { updateAddSearch } from "../App";

export var setAllProviders;
export var updateOTP;
export var updateFill;
export var changeMessage;
export var providerUpdate;
export var selectedUpdate;
export var number_;
export var changeProviderCount;
export var setLatLong;
export var setCurrentAddress;

export default function SlideUp(props){

    const [itemsSelected,UpdateSelected]=React.useState(false);
    const [showProvider,updateProvider]=React.useState(false);
    const [lat,setLat] = React.useState(undefined);
    const [long,setLong] = React.useState(undefined);
    const [name,setName] = React.useState("");
    const [number,setNumber]= React.useState("");
    const [currentProviders,SetProviders] = React.useState([]);
    const [otpBTN,setOTPButtonVis] = React.useState(false);
    const [fillOTP,fillVisibilityUpdate] =React.useState(false);
    const [message, updateMessage] = React.useState("");
    const [providerCount, updateProviderCount] = React.useState(undefined)
    const [currentAddress, updateCurrentAddress] = React.useState("")

    setAllProviders=SetProviders;
    updateOTP=setOTPButtonVis;
    updateFill = fillVisibilityUpdate;
    changeMessage=updateMessage;
    providerUpdate = updateProvider;
    selectedUpdate = UpdateSelected;
    number_=number;
    changeProviderCount = updateProviderCount;
    setLatLong = [setLat,setLong];
    setCurrentAddress = updateCurrentAddress;

    const updates = ()=>{
        
        payload['name']=name;
        payload['phone']=parseInt(number);
        payload['lat']=lat;
        payload['long']=long

        SetProviders([])
        console.log(currentProviders)
        getData();

        console.log(payload)
    }

    const getCurrentLocation = async()=>{


        updateAddSearch("block")
    }
    
    return(
        <div className="slider-main" id="slide">
            <div className="slide-content">
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0px 20px"}}>
                    {!providerCount && <p style={{borderLeft:"4px solid black", height:"10px", width:"10px", borderBottom:"4px solid black", transform:"rotate(45deg)",cursor:"pointer"}} onClick={()=>{UpdateSelected(false)}}></p>}
                    {providerCount && <p style={{padding:"10px", borderRadius:"20px" ,border:"2px solid red"}}>{providerCount} Providers</p>}
                    <p style={{border:"2px solid black", fontWeight:"600", borderRadius:"10px", padding:"1% 2%", background:"black", color:"white",cursor:"pointer"}} onClick={async ()=>{
                        
                        document.getElementById("slide").style.transform="translateY(200%)";
                        
                        UpdateSelected(false)
                        updateProvider(false)
                        
                        document.getElementById("provider").style.height="100%";
                        
                        props.updateCard([])
                        
                        await SetProviders([])
                        
                        resetPayload();
                        
                        setLat("");
                        setLong("");

                        fillVisibilityUpdate(false)

                        changeMessage("")
                        updateProviderCount(undefined)
                        updateCurrentAddress("")

                    }}><>CLOSE</></p>
                </div>
                <div className="services-card" id="provider" style={{height:"100%"}}>
                    {!itemsSelected && !showProvider &&
                    <>{props.cards}
                    </>
                    }
                     {itemsSelected && !showProvider &&
                     <>
                    <Form def="Enter Name" func={setName} type={"text"}/>
                    <Form def="Enter Phone Number" func={setNumber} type={"number"} maxLen={"10"}/>
                    <div onClick={()=>{getCurrentLocation()}} style={{
                        alignSelf:"center", maxWidth:"270px"
                    }}>
                        {currentAddress==="" && <p style={{textAlign:"center",color:"white", cursor:"pointer", fontWeight:"900" , fontFamily:"cardtite", backgroundColor:"rgb(255,74,74)", padding:"5px 10px", borderRadius:"20px"}}>Click Here To Choose Location</p>}
                        {currentAddress}
                    </div>
                       {otpBTN && <div className="action-btn" style={{margin:"0px auto"}}onClick={()=>{
                        getOTP(number)
                       }}>
                                Get OTP
                        </div>
                        }
                        {fillOTP && <OTPinput/>}
                        <p>{message}</p>
                    </>}
                    {showProvider && !itemsSelected && <>
                    {currentProviders}
                    </>}
                </div>
                {!showProvider && <div className="action-btn" onClick={()=>{
                    itemsSelected?updates():UpdateSelected(true)
                }}>
                    Next {">"}
                </div>}
            </div>
        </div>
    )
}