import { otps_ } from "./components/OTP";
import Provider from "./components/Provider";
import { changeProviderCount, providerUpdate, selectedUpdate, setAllProviders, updateFill, updateOTP } from "./components/SlideUp";
import { changeMessage } from "./components/SlideUp";

export var payload={
    fos:undefined,
    service:[],
    lat:undefined,
    long:undefined,
    phone:undefined,
    name:undefined,
    otp:Array(4),
    data:{}
}

var currentIndex=0;
var response;

export function updatePayload(key,value){
    payload[key]=value
    console.log(payload);
}

export function getAvailableIndex(){
    return currentIndex;
}

export function updateIndex(val){
    currentIndex=val;
}

export function resetPayload(){
    payload={
        "fos":undefined,
        "service":[],
        "lat":undefined,
        "long":undefined,
        "phone":undefined,
        "name":undefined,
        "otp":Array(4),
        "data":{}
    }
    currentIndex=0;
}

function updateServicePayload(){
    
    for(let i =1; i <payload['service'].length;i++){
    
        if(payload['service'][0]['name']===payload['service'][i]['name']){
            payload['service'].splice(0,1);
            return 0;
        }
    }
}

export async function getData() {
    
    if(payload['service'].length===0){
        changeMessage("No Service Selected");
        return 0;
    }
    if(!payload['lat'] && !payload['long']){
        changeMessage("Location Not Provided");
        return 0;
    }
    if(payload['name'].length===0){
        changeMessage("Name Not Provided");
        return 0;
    }
    if(!payload['phone']){
        changeMessage("Phone Number Not Provided");
        return 0;
    }

    await updateServicePayload();

    console.log(payload)


    payload['otp']=Number(payload['otp'].join(""))

    var raw = JSON.stringify(payload);
    var requestOptions = {
        method: 'POST',
        headers: {"content-type": "application/json; charset=UTF-8"},
        body: raw,
        redirect: 'follow',
        
      };
        response = await fetch("https://reachmayy.vercel.app/all_providers", requestOptions)
      response = await response.text()
      response=JSON.parse(response)
      console.log(response)
    if(response['0']){
            changeMessage("Wrong OTP Given");
            payload['otp']=Array(4);
            otps_.forEach((element) => {
                element.current.value=""
            });
            return 0;
    }
        
    if(response['message']){
            changeMessage("Please Provide Name, Phone Number and Location");
            return 0;
    }

    if(!response['providers']){
        console.log("no provider found")
        setAllProviders(<p>No Providers Available</p>)
        providerUpdate(true);
        selectedUpdate(false);
        return 0;
    }

    if(response['providers']){
            
            document.getElementById("provider").style.height="80%"

            let providers=[]
            changeProviderCount(response['providers'].length)
            for (var i=0; i<response['providers'].length; i++){
                   await providers.push(<Provider name={response['providers'][i]['name']} id={response['providers'][i]['id']} rating={response['providers'][i]['rating']}  age={response['providers'][i]['age']} cost={response['providers'][i]['cost']} fos={response['providers'][i]['FoS']} num={response['providers'][i]['phone']}/>)
            }
            var jwt = response['JWT'];
            
            localStorage.setItem("JWT",jwt)

            setAllProviders(providers);
            providerUpdate(true);
            selectedUpdate(false);

            

            window.localStorage.setItem('name',payload['name'])
            window.localStorage.setItem('number', payload['phone'])

            }

  }
  

 export async function getOTP(phoneNum){
    
    updateOTP(false)
    updateFill(true)
    
    var requestOptions = {
        method: 'GET'
      }

     fetch("https://reachmayy.vercel.app/get_otp?number="+phoneNum,requestOptions)

 }


 export async function updateLeadCount(phone,cost){

    var pay = {
        name:payload['name'],
        provider_phone:phone,
        lat:payload['lat'],
        long:payload['long'],
        earning:cost,
        data:payload['data']
    }

    console.log(pay)
    var requestOptions ={
        method: 'POST',
        headers: {"content-type": "application/json; charset=UTF-8","Authorization":`Bearer ${localStorage.getItem("JWT")}`},
        body: JSON.stringify(pay),
        redirect: 'follow',
        
    }

    response = await fetch("https://reachmayy.vercel.app/update_lead_count", requestOptions)
    response = await response.text()
    response = JSON.parse(response)

    if(response['msg']){
        alert("Search Expired, Please Search Again")
    }

 }

 export async function resendOTP(phoneNum){
 
    var requestOptions = {
        method: 'GET'
      }

     fetch("https://reachmayy.vercel.app/resend_OTP?number="+phoneNum,requestOptions)

 }