import React from "react";
import { payload, resendOTP } from "../Connection";
import { number_ } from "./SlideUp";

export var otps_;

export default function OTPinput(){
    
    var otpArray= [React.createRef(),React.createRef(),React.createRef(),React.createRef()]

    otps_=otpArray;

    return(
        <>
        <div className="user-form" style={{display:"flex", padding:"0px 20px", gap:"20px", marginTop:"20px"}} >
            <input type="number" ref={otpArray[0]} min={0} max={9}style={{textAlign:"center"}}onInput={()=>{
                if(1)
                {otpArray[0].current.value=otpArray[0].current.value.slice(0,1)}

            }} onChange={()=>{

                payload['otp'][0]=otpArray[0].current.value
                if(otpArray[0].current.value.length>0){
                    document.getElementById("otp1").focus()
                }
            }}
            />

            <input type="number" id="otp1" ref={otpArray[1]} min={0} max={9} onInput={()=>{
                if(1)
                {otpArray[1].current.value=otpArray[1].current.value.slice(0,1)}

            }} style={{textAlign:"center"}} onChange={()=>{

                payload['otp'][1]=otpArray[1].current.value

                if(otpArray[1].current.value.length>0){
                    document.getElementById("otp2").focus()
                }

            }}/>

            <input type="number"  id="otp2" ref={otpArray[2]} min={0} max={9} onInput={()=>{
                if(1)
                {otpArray[2].current.value=otpArray[2].current.value.slice(0,1)}

            }} style={{textAlign:"center"}} onChange={()=>{
                
                payload['otp'][2]=otpArray[2].current.value

                if(otpArray[2].current.value.length>0){
                    document.getElementById("otp3").focus()
                }

            }}/>

            <input type="number" id="otp3" ref={otpArray[3]} min={0} max={9} onInput={()=>{
                if(1)
                {otpArray[3].current.value=otpArray[3].current.value.slice(0,1)}

            }} style={{textAlign:"center"}} onChange={()=>{

                payload['otp'][3]=otpArray[3].current.value
                console.log(payload['otp'])
            }}/>

        </div>
        <div onClick={()=>{resendOTP(number_)}}>
                        <p style={{textAlign:"center",color:"red", cursor:"pointer", fontWeight:"600" , fontFamily:"cardtite"}}>Resend OTP</p>
                    </div>
        </>
    )
}